import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

function InstitutionInterestRow3({ data }) {
  const usageOfTool = data.usageOfTool || [];
  const dataPackages = data.dataPackages || [];
  const sampleDownloads = data.sampleDownloads || [];

  const formatChartData = (items) => items.map((item) => ({
    name: typeof item.name === 'string' ? item.name.replace("_freq", "") : 'Unknown',
    value: item.count,
  }));
  

  const barChartData = formatChartData(usageOfTool);
  const packageChartData = formatChartData(dataPackages);
  const sampleChartData = formatChartData(sampleDownloads);

  const paperStyle = {
    padding: "10px",
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const renderBarChart = (data, title) => (
    <Paper elevation={3} style={paperStyle}>
      <Typography variant="subtitle1" marginBottom={2}>
        {title}
      </Typography>
      {data && data.length > 0 ? (
        <BarChart
          width={250}
          height={180}
          data={data}
          style={{ marginLeft: "-60px" }}
        >
          <XAxis
            dataKey="name"
            stroke="#1a97db"
            angle={-45}
            textAnchor="end"
            fontSize={9}
            height={50}
            fontWeight="Bold"
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#48ace2" />
        </BarChart>
      ) : (
        <Typography variant="subtitle1" align="center" fontWeight="Bold">
          There is not related data.
        </Typography>
      )}
    </Paper>
  );

  return (
    <Grid
      container
      spacing={3}
      paddingBottom={20}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={4}>
        {renderBarChart(barChartData, "Usage Of Tool")}
      </Grid>
      <Grid item xs={4}>
        {renderBarChart(packageChartData, "Favorite Data Packages")}
      </Grid>
      <Grid item xs={4}>
        {renderBarChart(sampleChartData, "Favorite Samples")}
      </Grid>
    </Grid>
  );
}

export default InstitutionInterestRow3;
