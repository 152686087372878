import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

function UserInterestRow3({ data }) {
  const usageOfTool = data.usageOfTool || [];
  const barChartData = usageOfTool.map((tool) => ({
    name: tool.tool_name.replace("_freq", ""),
    value: tool.count,
  }));

  // const uploadData = [
  //   { name: "File1", value: 1000 },
  //   { name: "File2", value: 800 },
  //   { name: "File3", value: 700 },
  //   { name: "File4", value: 600 },
  //   { name: "File5", value: 500 },
  // ];

  // const downloadData = [
  //   { name: "FileA", value: 4000 },
  //   { name: "FileB", value: 3000 },
  //   { name: "FileC", value: 2500 },
  //   { name: "FileD", value: 2000 },
  //   { name: "FileE", value: 1500 },
  // ];

  const paperStyle = {
    padding: "10px",
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  // const tableStyle = {
  //   width: "70%",
  // };

  return (
    <Grid
      container
      spacing={3}
      paddingBottom={20}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            Usage Of Tool
          </Typography>
          {barChartData && barChartData.length > 0 ? (
            <BarChart
              width={250}
              height={180}
              data={barChartData}
              style={{ marginLeft: "-60px" }}
            >
              <XAxis
                dataKey="name"
                stroke="#1a97db"
                angle={-45}
                textAnchor="end"
                fontSize={9}
                height={50}
                fontWeight="Bold"
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#48ace2" />
            </BarChart>
          ) : (
            <Typography variant="subtitle1" align="center" fontWeight="Bold">
              There is not related data.
            </Typography>
          )}
        </Paper>
      </Grid>

      {/* <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            How much data uploaded?
          </Typography>
          <Table size="small" style={tableStyle}>
            <TableBody>
              {uploadData.map((data, index) => (
                <TableRow key={data.name}>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: index === 0 ? "#1E90FF" : "black",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {data.name}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: index === 0 ? "#1E90FF" : "black",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {data.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid> */}

      {/* <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            How much data downloaded?
          </Typography>
          <Table size="small" style={tableStyle}>
            <TableBody>
              {downloadData.map((data, index) => (
                <TableRow key={data.name}>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: index === 0 ? "#1E90FF" : "black",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {data.name}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: index === 0 ? "#1E90FF" : "black",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {data.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid> */}
    </Grid>
  );
}

export default UserInterestRow3;
