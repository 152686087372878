import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem, Typography } from "@mui/material";
import { fetchInstIdList, fetchInstData } from "../../api";
import Row1 from "../charts/InstitutionActivityRow1";
import Row2 from "../charts/InstitutionActivityRow2";

function InstitutionInterestBox() {
  const [insts, setInsts] = useState([]);
  const [selectedInst, setSelectedInst] = useState("");
  const [instData, setInstData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const instIds = await fetchInstIdList();
      setInsts(instIds);
      if (instIds && instIds.length > 0) {
        setSelectedInst(instIds[0].user_id);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedInstDetails = async () => {
      if (!selectedInst) return;
      try {
        const data = await fetchInstData(selectedInst);
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          setInstData(data);
          setErrorMessage(null);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchSelectedInstDetails();
  }, [selectedInst]);

  return (
    <Grid container spacing={3} style={{ width: "100%" }} justifyContent="center">
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" color={"#414291"} fontWeight={"Bold"} paddingBottom={2}>
          Institution Activity Dashboard
        </Typography>
        <Typography variant="subtitle2" color={"#1192d7"}>
          Select Institution
        </Typography>
        <Select
          value={selectedInst}
          onChange={(e) => setSelectedInst(e.target.value)}
          sx={{
            width: "250px",
            backgroundColor: "deepblue",
            color: "Black",
            "&:before": {
              borderColor: "deepblue",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderColor: "deepblue",
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                height: "500px",
              },
            },
          }}
        >
          {insts.map(({ user_id, name }) => (
            <MenuItem key={user_id} value={user_id}>
              {name} ({user_id})
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {errorMessage ? (
        <Grid item xs={12}>
          <Typography variant="h4" color="error" align="center" fontWeight={"Bold"}>
            {errorMessage}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Row1 data={instData} />
          </Grid>
          <Grid item xs={12}>
            <Row2 data={instData} />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default InstitutionInterestBox;