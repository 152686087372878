import React from "react";
import {
  Paper,
  Typography,
  Grid
} from "@mui/material";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip
} from "recharts";

function calculateOverlayAlpha(maxValue, currentValue) {
  const ratio = currentValue / maxValue;
  const alpha = Math.pow(ratio, 2) * 28;
  return alpha;
}

function MainRow6({ originOfUser }) {
  try {
    const sortedOriginOfUser = [...originOfUser].sort(
      (a, b) => b.count - a.count
    );

    const pieData = sortedOriginOfUser.map((item) => {
      return { name: item.Country, value: item.count };
    });

    // Extract the top 10 for the legend
    const top10LegendData = pieData.slice(0, 10);

    const renderTooltipContent = ({ active, payload }) => {
      if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: "#cbe7f6",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <p style={{ color: "black", margin: 0 }}>{`${data.name}: ${
              data.value
            } (${(
              (data.value / (pieData[0].value + pieData[1].value)) *
              100
            ).toFixed(2)}%)`}</p>
          </div>
        );
      }
      return null;
    };

    const maxPieValue = Math.max(...pieData.map((item) => item.value));

    return (
      <Grid container spacing={3} paddingTop={5} style={{ marginTop: "-80px", marginBottom:"30px"}}>
        {/* Pie Chart */}
        <Grid item xs={4} style={{ height: "580px"}}>
          <Paper
            style={{
              height: "100%",
              textAlign: "center",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              align="center"
            >
              Origin of Requests
            </Typography>
            <PieChart width={300} height={300}>
              <Pie
                data={pieData}
                dataKey="value"
                innerRadius={0}
                outerRadius={120}
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={`rgba(23, 136, 197, ${calculateOverlayAlpha(
                      maxPieValue,
                      entry.value
                    )})`}
                  />
                ))}
              </Pie>
              <Tooltip content={renderTooltipContent} />
              <Legend
                payload={top10LegendData.map((item, index) => ({
                  value: `${item.name} (${item.value})`,
                  type: 'square',
                  id: item.name,
                  color: `rgba(23, 136, 197, ${calculateOverlayAlpha(maxPieValue, item.value)})`
                }))}
                verticalAlign="bottom"
                height={36}
                wrapperStyle={{ fontSize: "15px" }}
              />
            </PieChart>
          </Paper>
        </Grid>
      </Grid>
    );
  } catch (error) {
    console.error(error);
    return <div>Error: {error.message}</div>;
  }
}

export default MainRow6;
