import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
} from "recharts";

function InstitutionInterestRow2({ data }) {
  const timeSpentMapData = data.timeSpentMapData || [];
  const usagePerTechnique = data.perferTechnique || null;
  const timeSpentTotalVisit = data.timeSpentTotalVisit || [];

  const totalSpentTime = timeSpentMapData.reduce((acc, val) => acc + val, 0);
  const mapPercentage = ((timeSpentMapData[0] || 0) / totalSpentTime * 100).toFixed(2);
  const dataPercentage = ((timeSpentMapData[1] || 0) / totalSpentTime * 100).toFixed(2);

  // const barData = usagePerTechnique.map(item => ({ name: item.os_family, times: item.count }));
  const barData = usagePerTechnique;

  const paperStyle = {
    padding: "10px",
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Grid
      container
      spacing={3}
      paddingBottom={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            How much time spent on Map vs Data?
          </Typography>
          {(typeof mapPercentage !== "undefined" && mapPercentage !== null) ||
          (typeof dataPercentage !== "undefined" && dataPercentage !== null) ? (
            <>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Map: {mapPercentage || 0}%
              </Typography>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Data: {dataPercentage || 0}%
              </Typography>
              <img
                src={`${process.env.PUBLIC_URL}/map.png`}
                alt="Map"
                style={{
                  borderRadius: "50%",
                  width: "50%",
                  objectFit: "contain",
                }}
              />
            </>
          ) : (
            <Typography variant="subtitle1" align="center" fontWeight="Bold">
              There is not related data.
            </Typography>
          )}
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            Preferred technique
          </Typography>
          {barData && barData.length ? (
            // <BarChart width={280} height={100} data={barData} layout="vertical">
            //   <XAxis type="number" />
            //   <YAxis dataKey="name" type="category" stroke="#1a97db" width={100} />
            //   <Tooltip />
            //   <Legend />
            //   <Bar dataKey="times" fill="#48ace2" />
            // </BarChart>
            <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    ariant="subtitle1"
                    align="center"
                    fontWeight="Bold"
                  >
                    {barData}
                  </Typography>
                </div>
          ) : (
            <Typography variant="subtitle1" align="center" fontWeight="Bold">
              There is not related data.
            </Typography>
          )}
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            Time spent (Total Visit)
          </Typography>
          {timeSpentTotalVisit && timeSpentTotalVisit.length ? (
            <LineChart
              width={300}
              height={200}
              margin={{ top: 10, right: 40, bottom: 0, left: 0 }}
              data={timeSpentTotalVisit}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#48ace2" />
            </LineChart>
          ) : (
            <Typography variant="subtitle1" align="center" fontWeight="Bold">
              There is not related data.
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default InstitutionInterestRow2;
