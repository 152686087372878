const baseURL = "https://dashboard.lithosurfer.io/api/";

// // authentication
// export async function authenticate() {
//   try {
//     const response = await fetch(`${baseURL}Login/`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "*",
//         Referrer: "http://ddns.qwq.ie:8080/",
//       },
//       body: JSON.stringify({
//         user_name: "88888",
//         password: "88888",
//       }),
//     });

//     const data = await response.json();

//     if (data.message === "You are logged in successfully") {
//       localStorage.setItem("token", data.token);
//       return data.token;
//     } else {
//       throw new Error(data.message);
//     }
//   } catch (error) {
//     throw error;
//   }
// }

// check if the user is logged in
export async function isLoggedIn() {
  try {
    const response = await fetch(`${baseURL}LoginCheck/`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensure cookies are sent when making cross-domain requests
    });
    return response.ok;
  } catch (error) {
    return false;
  }
}


// login
export async function login(username, password) {
  try {
    const response = await fetch(`${baseURL}Login/`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_name: username, password: password }),
      credentials: 'include', // Ensure cookies are sent when making cross-domain requests
    });

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error when logging in:', error);
    return false;
  }
}

// logout
export async function logout() {
  try {
    const response = await fetch(`${baseURL}Logout/`, {
      method: "POST",
      credentials: 'include', // Guaranteed to send cookies on cross-domain requests
    });
    
    if (response.ok) {
      console.log("Logged out successfully");
      return true;
    } else {
      console.error("Logout failed");
      return false;
    }
  } catch (error) {
    console.error("Logout error:", error);
    return false;
  }
}


// overview
export async function fetchOverviewData() {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${baseURL}Overview/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
      credentials: 'include', // Ensure cookies are sent when making cross-domain requests
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

// user
export async function fetchUserIdList() {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${baseURL}Id_list/?is_inst=0`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
      credentials: 'include', // Ensure cookies are sent when making cross-domain requests
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.users;
  } catch (error) {
    throw error;
  }
}

export async function fetchUserData(selectedUser) {
  try {
    const response = await fetch(`${baseURL}User/?user_id=${selectedUser}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensure cookies are sent when making cross-domain requests
    });
    const contentType = response.headers.get("content-type");
    if (response.ok) {
      if (contentType && contentType.includes("application/json")) {
        return await response.json();
      } else {
        throw new Error(`Server returned non-JSON response`);
      }
    }
    if (response.status === 404) {
      throw new Error(
        `User information with ID ${selectedUser} cannot be found`
      );
    }
    if (response.status === 500) {
      throw new Error(`Server error`);
    }
    if (response.status === 401) {
      throw new Error(`Unauthorized`);
    }
    // If we got this far, something else went wrong
    throw new Error(`Unknown error`);
  } catch (error) {
    return { error: error.message };
  }
}

// institution
export async function fetchInstIdList() {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${baseURL}Id_list/?is_inst=1`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
      credentials: 'include', // Ensure cookies are sent when making cross-domain requests
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.users;
  } catch (error) {
    throw error;
  }
}

export async function fetchInstData(selectedInst) {
  try {
    const response = await fetch(
      `${baseURL}Institution/?inst_id=${selectedInst}` , {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensure cookies are sent when making cross-domain requests
    }
    );
    const contentType = response.headers.get("content-type");
    if (response.ok) {
      if (contentType && contentType.includes("application/json")) {
        return await response.json();
      } else {
        throw new Error(`Server returned non-JSON response`);
      }
    }
    if (response.status === 404) {
      throw new Error(
        `Institution information with ID ${selectedInst} cannot be found`
      );
    }
    if (response.status === 500) {
      throw new Error(`Server error`);
    }
    if (response.status === 401) {
      throw new Error(`Unauthorized`);
    }
    // If we got this far, something else went wrong
    throw new Error(`Unknown error`);
  } catch (error) {
    return { error: error.message };
  }
}

// export async function fetchPredictionData() {
//   const token = localStorage.getItem("token");

//   try {
//     const response = await fetch(`${baseURL}Prediction/`, {
//       method: "GET",
//       headers: {
//         Authorization: `Token ${token}`,
//       },
//     });

//     const data = await response.json();
//     return data;
//   } catch (error) {
//     throw error;
//   }
// }
