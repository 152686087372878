import React, { useState, useMemo } from "react";
import {
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControl,
  InputLabel,
} from "@mui/material";

const filterRequestsByTime = (timestamps, filter) => {
  if (!timestamps || !Array.isArray(timestamps)) {
    return 0;
  }

  const now = Date.now();
  let fromDate;

  switch (filter) {
    case 'week':
      fromDate = now - 7 * 24 * 60 * 60 * 1000;
      break;
    case 'month':
      fromDate = now - 30 * 24 * 60 * 60 * 1000;
      break;
    case 'threeMonths':
      fromDate = now - 3 * 30 * 24 * 60 * 60 * 1000;
      break;
    case 'halfyear':
      fromDate = now - 6 * 30 * 24 * 60 * 60 * 1000;
      break;
    case 'year':
      fromDate = now - 12 * 30 * 24 * 60 * 60 * 1000;
      break;
    case 'all':
    default:
      return timestamps.length;
  }

  return timestamps.filter(timestamp => timestamp >= fromDate).length;
};

const calculateUserRequests = (userList, timeFilter) => {
  const userRequests = userList.reduce((acc, user) => {
    const requestCount = filterRequestsByTime(user.req_timestamp, timeFilter);
    if (requestCount > 0) {
      const userName = user.name || 'Unknown';
      acc[userName] = (acc[userName] || 0) + requestCount;
    }
    return acc;
  }, {});

  return Object.entries(userRequests).map(([name, requestCount]) => ({
    name,
    requestCount,
  }));
};


function MainRow4({
  inactiveUserList = [],
  activeUserList = [],
  frequencyUV,
  technique,
}) {
  const [inactivePeriod, setInactivePeriod] = useState(30);
  const [activePeriod, setActivePeriod] = useState('all');

  const activeUsers = useMemo(() => calculateUserRequests(activeUserList, activePeriod), [activeUserList, activePeriod]);

  const renderInactiveUserList = (userList = []) => {
    if (!userList || userList.length === 0) {
      return (
        <Typography align="center" style={{ marginTop: "10px" }}>
          No users found
        </Typography>
      );
    }

    const userData = userList.map((item) => {
      const currentDate = new Date();
      const lastRequestDate = new Date(item.last_req_time);
      const differenceInTime = currentDate - lastRequestDate;
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      );
      const differenceInHours = Math.floor(differenceInTime / (1000 * 60 * 60));

      return {
        name: `User ${item.name}`,
        daysInactive: differenceInDays,
        hoursInactive: differenceInHours,
        isActiveLessThanADay: differenceInDays < 1,
      };
    });

    const sortedUsers = userData.sort((a, b) => {
      if (a.isActiveLessThanADay && b.isActiveLessThanADay) {
        return a.hoursInactive - b.hoursInactive;
      }
      return a.daysInactive - b.daysInactive;
    });

    return (
      <List>
        {sortedUsers.map((user) => (
          <ListItem key={user.name}>
            <ListItemText
              primary={user.name}
              secondary={
                user.isActiveLessThanADay
                  ? `${user.hoursInactive} hours inactive`
                  : `${user.daysInactive} days inactive`
              }
              style={{ display: "flex", justifyContent: "space-between" }}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const renderActiveUserList = (userList = []) => {
    if (!userList || userList.length === 0) {
      return (
        <Typography align="center" style={{ marginTop: "10px" }}>
          No data presented
        </Typography>
      );
    }
  
    const sortedUsers = userList.sort((a, b) => b.requestCount - a.requestCount);
  
    return (
      <List>
        {sortedUsers.map((user, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`User ${user.name}`}
              secondary={`${user.requestCount} visits`}
              style={{ display: "flex", justifyContent: "space-between" }}
            />
          </ListItem>
        ))}
      </List>
    );
  };
  

  const totalCount = technique.reduce((acc, tech) => acc + tech.count, 0);
  const techniqueWithPercent = technique.map((tech) => ({
    ...tech,
    percent: (tech.count / totalCount) * 100,
  }));

  return (
    <Grid container spacing={3} paddingTop={5}>
      <Grid item xs={4} style={{ height: "600px" }}>
        <Paper style={{ height: "100%", overflow: "auto" }}>
          <Typography
            variant="h6"
            align="center"
            paddingBottom={0.5}
            paddingTop={0.5}
          >
            Inactive User List
          </Typography>
          <Select
            value={inactivePeriod}
            onChange={(e) => setInactivePeriod(e.target.value)}
            fullWidth
          >
            <MenuItem value={71}>Less than 1 week</MenuItem>
            <MenuItem value={7}>More than 1 week</MenuItem>
            <MenuItem value={30}>More than 30 days</MenuItem>
            <MenuItem value={90}>More than 90 days</MenuItem>
            <MenuItem value={180}>More than 6 months</MenuItem>
            <MenuItem value={365}>More than 1 year</MenuItem>
            <MenuItem value={730}>More than 2 years</MenuItem>
          </Select>
          {renderInactiveUserList(inactiveUserList)}
        </Paper>
      </Grid>

      <Grid item xs={4} style={{ height: "600px" }}>
        <Paper style={{ height: "100%", overflow: "auto" }}>
          <Typography
            variant="h6"
            align="center"
            paddingBottom={0.5}
            paddingTop={0.5}
          >
            Active User List
          </Typography>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="active-user-time-filter-label">Time Filter</InputLabel>
            <Select
              labelId="active-user-time-filter-label"
              value={activePeriod}
              onChange={(e) => setActivePeriod(e.target.value)}
              label="Time Filter"
            >
              <MenuItem value="week">Past Week</MenuItem>
              <MenuItem value="month">Past Month</MenuItem>
              <MenuItem value="threeMonths">Past Three Months</MenuItem>
              <MenuItem value="halfyear">Past Half Year</MenuItem>
              <MenuItem value="year">Past Year</MenuItem>
              <MenuItem value="all">All Time</MenuItem>
            </Select>
          </FormControl>
          {renderActiveUserList(activeUsers)}
        </Paper>
      </Grid>

      <Grid item xs={4} style={{ height: "520px" }}>
        <Paper
          sx={{
            height: "70%",
            padding: 2,
            marginBottom: 2,
            align: "center",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle1"
            align="center"
            style={{ marginTop: "10px" }}
          >
            Requests from Techniques
          </Typography>
          <Table style={{ alignContent: "center", border: "none" }}>
            <TableBody>
              {techniqueWithPercent.map((tech, index) => (
                <TableRow key={index}>
                  <TableCell style={{ borderBottom: "none" }}>
                    {tech.os_family}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    {tech.percent.toFixed(2)}%
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    {tech.count} {tech.count > 1 ? "requests" : "request"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {/* Total Visits */}
        <Paper
          sx={{
            height: "30%",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle1"
            align="center"
            style={{ marginTop: "10px" }}
          >
            User Visits
          </Typography>
          <Typography variant="subtitle2" align="center">
            Multiple visits per day by one user count as one
          </Typography>
          <Typography
            variant="h6"
            align="center"
            style={{
              fontWeight: "bold",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {frequencyUV}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MainRow4;
