import React, { useState, useMemo } from "react";
import {
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Rectangle,
} from "recharts";

function calculateBlueShade(maxValue, currentValue) {
  const ratio = currentValue / maxValue;

  const startColor = { r: 42, g: 50, b: 133 };
  const endColor = { r: 17, g: 140, b: 218 };

  const deltaColor = {
    r: endColor.r - startColor.r,
    g: endColor.g - startColor.g,
    b: endColor.b - startColor.b,
  };

  const interpolatedColor = {
    r: Math.round(startColor.r + deltaColor.r * ratio),
    g: Math.round(startColor.g + deltaColor.g * ratio),
    b: Math.round(startColor.b + deltaColor.b * ratio),
  };

  return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
}

const filterTimestampsByTime = (timestamps, filter) => {
  const now = Date.now();
  let fromDate;

  switch (filter) {
    case "week":
      fromDate = now - 7 * 24 * 60 * 60 * 1000;
      break;
    case "month":
      fromDate = now - 30 * 24 * 60 * 60 * 1000;
      break;
    case "halfyear":
      fromDate = now - 6 * 30 * 24 * 60 * 60 * 1000;
      break;
    case "year":
      fromDate = now - 12 * 30 * 24 * 60 * 60 * 1000;
      break;
    case "all":
    default:
      return timestamps.length;
  }

  return timestamps.filter((timestamp) => timestamp >= fromDate).length;
};

const calculateCountsByTime = (data, timeFilter) => {
  return data
    .map((item) => ({
      select_mode: item.select_mode,
      count: filterTimestampsByTime(item.req_timestamp, timeFilter),
    }))
    .filter((item) => item.count > 0)
};

const calculateLayerCountsByTime = (data, timeFilter) => {
  return data
    .map((item) => ({
      layer_selected: item.layer_selected,
      count: timeFilter === "all" ? item.overall_count : item[`${timeFilter}_count`],
    }))
    .filter((item) => item.count > 0)
};

const calculateSampleCountsByTime = (data, timeFilter) => {
  return data
    .map((item) => ({
      name: item.name,
      count: filterTimestampsByTime(item.req_timestamp, timeFilter),
    }))
    .filter((item) => item.count > 0)
};

function MainRow5({ selectedModes, selectedLayers, selectedSamples }) {
  const [timeFilterModes, setTimeFilterModes] = useState("all");
  const [timeFilterLayers, setTimeFilterLayers] = useState("all");
  const [timeFilterSamples, setTimeFilterSamples] = useState("all");

  const filteredModes = useMemo(
    () => calculateCountsByTime(selectedModes, timeFilterModes),
    [selectedModes, timeFilterModes]
  );
  const totalModes = filteredModes.reduce((acc, mode) => acc + mode.count, 0);

  filteredModes.forEach((mode) => {
    mode.percent = (mode.count / totalModes) * 100;
  });
  filteredModes.sort((a, b) => b.count - a.count);

  const filteredLayers = useMemo(
    () => calculateLayerCountsByTime(selectedLayers, timeFilterLayers),
    [selectedLayers, timeFilterLayers]
  );
  const totalLayers = filteredLayers.reduce(
    (acc, layer) => acc + layer.count,
    0
  );
  filteredLayers.forEach((layer) => {
    layer.percent = (layer.count / totalLayers) * 100;
  });
  filteredLayers.sort((a, b) => b.count - a.count);

  const filteredSamples = useMemo(
    () => calculateSampleCountsByTime(selectedSamples, timeFilterSamples),
    [selectedSamples, timeFilterSamples]
  );
  const totalSamples = filteredSamples.reduce(
    (acc, sample) => acc + sample.count,
    0
  );
  filteredSamples.forEach((sample) => {
    sample.percent = (sample.count / totalSamples) * 100;
  });
  filteredSamples.sort((a, b) => b.count - a.count);

  const maxCountLayers = Math.max(...filteredLayers.map((item) => item.count));
  const maxCountModes = Math.max(...filteredModes.map((item) => item.count));
  const maxCountSamples = Math.max(
    ...filteredSamples.map((item) => item.count)
  );

  function CustomBar1(props) {
    const { x, y, width, height, payload } = props;
    const customFill = calculateBlueShade(maxCountLayers, payload.count);
    return (
      <Rectangle x={x} y={y} width={width} height={height} fill={customFill} />
    );
  }

  function CustomBar2(props) {
    const { x, y, width, height, payload } = props;
    const customFill = calculateBlueShade(maxCountModes, payload.count);
    return (
      <Rectangle x={x} y={y} width={width} height={height} fill={customFill} />
    );
  }

  function CustomBar3(props) {
    const { x, y, width, height, payload } = props;
    const customFill = calculateBlueShade(maxCountSamples, payload.count);
    return (
      <Rectangle x={x} y={y} width={width} height={height} fill={customFill} />
    );
  }

  // Debug output to check data
  console.log("Filtered Layers: ", filteredLayers);
  console.log("Filtered Modes: ", filteredModes);
  console.log("Filtered Samples: ", filteredSamples);

  return (
    <Grid container spacing={3} paddingTop={5} paddingBottom={10}>
      {[
        { data: filteredModes, title: "Favourite Selected Modes", dataKey: "select_mode", customBar: CustomBar2, timeFilter: timeFilterModes, setTimeFilter: setTimeFilterModes, labelId: "time-filter-modes-label", chartWidth: 300, chartHeight: 400 },
        { data: filteredLayers, title: "Favourite Selected Layers", dataKey: "layer_selected", customBar: CustomBar1, timeFilter: timeFilterLayers, setTimeFilter: setTimeFilterLayers, labelId: "time-filter-layers-label", chartWidth: 600, chartHeight: 400 },
        { data: filteredSamples, title: "Favourite Selected Samples", dataKey: "name", customBar: CustomBar3, timeFilter: timeFilterSamples, setTimeFilter: setTimeFilterSamples, labelId: "time-filter-samples-label", chartWidth: 500, chartHeight: 400 }
      ].map((chart, index) => (
        <Grid item xs={chart.chartWidth === 300 ? 2.5 : (chart.chartWidth === 600 ? 5 : 4.5)} key={index} style={{ height: "600px" }}>
          <Paper
            style={{
              height: "100%",
              textAlign: "center",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" align="center" style={{ marginTop: "10px" }}>
              {chart.title}
            </Typography>
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel id={chart.labelId}>Time Filter</InputLabel>
              <Select
                labelId={chart.labelId}
                value={chart.timeFilter}
                onChange={(e) => chart.setTimeFilter(e.target.value)}
                label="Time Filter"
              >
                <MenuItem value="week">Past Week</MenuItem>
                <MenuItem value="month">Past Month</MenuItem>
                <MenuItem value="halfyear">Past Half Year</MenuItem>
                <MenuItem value="year">Past Year</MenuItem>
                <MenuItem value="all">All Time</MenuItem>
              </Select>
            </FormControl>
            {chart.data.length > 0 ? (
              <BarChart width={chart.chartWidth} height={chart.chartHeight} data={chart.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={chart.dataKey}
                  angle={-60}
                  textAnchor="end"
                  fontSize={10}
                  height={150}
                  fontWeight="Bold"
                />
                <YAxis fontSize={8} />
                <Tooltip />
                <Bar dataKey="count" fill="#8884d8" shape={<chart.customBar />} />
              </BarChart>
            ) : (
              <Typography>No data about this time filter.</Typography>
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
export default MainRow5;
