import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Fab,
  IconButton,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import UserFooter from "./UserFooter";
import "./SideBar.css";

function SideBar({ setSelectedComponent }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <div
        style={{
          width: isOpen ? "240px" : "0px",
          flexShrink: 0,
          borderTopRightRadius: "20px",
          overflowX: "hidden",
          transition: "width 0.3s",
        }}
      >
        <Drawer variant="persistent" open={isOpen}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <img
                  style={{ width: "150px" }}
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  alt="Logo"
                />
                {isOpen && (
                  <IconButton onClick={() => setIsOpen(false)}>
                    <ChevronLeftIcon />
                  </IconButton>
                )}
              </div>
              <Divider />
              <List>
                <ListItemText
                  primary="Main Dashboard"
                  sx={{
                    fontWeight: 800,
                    paddingLeft: "15px",
                  }}
                />
                <ListItem
                  button
                  onClick={() => setSelectedComponent("Overview")}
                >
                  <ListItemText primary="Overview" className="list-item-text" />
                </ListItem>
                <ListItemText
                  primary="User Dashboard"
                  sx={{
                    fontWeight: 800,
                    paddingLeft: "15px",
                  }}
                />
                <ListItem
                  button
                  onClick={() => setSelectedComponent("User Interest")}
                >
                  <ListItemText
                    primary="User Interest"
                    className="list-item-text"
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => setSelectedComponent("User Activity")}
                >
                  <ListItemText
                    primary="User Activity"
                    className="list-item-text"
                  />
                </ListItem>
                <ListItemText
                  primary="Institution Dashboard"
                  sx={{
                    fontWeight: 800,
                    paddingLeft: "15px",
                  }}
                />
                <ListItem
                  button
                  onClick={() => setSelectedComponent("Institution Interest")}
                >
                  <ListItemText
                    primary="Institution Interest"
                    className="list-item-text"
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => setSelectedComponent("Institution Activity")}
                >
                  <ListItemText
                    primary="Institution Activity"
                    className="list-item-text"
                  />
                </ListItem>
              </List>
            </Box>
            <UserFooter />
          </Box>
        </Drawer>
      </div>
      {!isOpen && (
        <Fab
          color="primary"
          aria-label="menu"
          style={{ position: "fixed", bottom: "20px", left: "20px" }}
          onClick={() => setIsOpen(true)}
        >
          <MenuIcon />
        </Fab>
      )}
    </div>
  );
}

export default SideBar;
