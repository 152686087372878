import React, { useState, useEffect } from 'react';
import { isLoggedIn, login, logout } from "../../api"; // Ensure import from API

function LoginModal() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [isHovered, setIsHovered] = useState(false); // For button hover state

  useEffect(() => {
    const checkLoggedIn = async () => {
      const logged = await isLoggedIn();
      setShowModal(!logged);
    };

    checkLoggedIn();
  }, []);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loggedIn = await login(username, password);
    if (loggedIn) {
      console.log('Logged in successfully');
      window.location.reload();
    } else {
      console.log('Login failed');
    }
  };

  const loginBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    padding: '20px',
    backgroundColor: 'white',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
  };

  const inputStyle = {
    margin: '10px 0',
    height: '38px',
    width: '100%',
  };

  const buttonStyle = {
    backgroundColor: isHovered ? '#0056b3' : '#007bff', // Hover effect
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    height: '38px',
    width: '100%',
    marginTop: '20px',
  };

  if (!showModal) {
    // Only show logout button if logged in
    return (
      <button onClick={logout} style={buttonStyle}>
        Logout
      </button>
    );
  }

  return (
    <div style={loginBoxStyle}>
      {/* Logo at the top */}
      <img
        style={{ width: "150px", marginBottom: "20px" }} // Adjust logo size and add margin below
        src={`${process.env.PUBLIC_URL}/logo.png`}
        alt="Logo"
      />
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            style={inputStyle}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            style={inputStyle}
          />
        </label>
        <button
          type="submit"
          style={buttonStyle}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default LoginModal;
