import React from "react";
import {
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  Tooltip,
  XAxis,
  Legend,
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
} from "recharts";

function MainRow2({ mostPopularArea, timeSpentTotal, predictionData }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  try {
    const lineData = timeSpentTotal.map((item) => ({
      name: item.name,
      total: item.count,
    }));

    const barChartWidth = isLargeScreen ? 400 : 200;
    const barChartHeight = isLargeScreen ? 200 : 125;

    const predictionLineData = predictionData
      ? predictionData.time.slice(0, 30).map((time, index) => ({
          time: time,
          // round the value to 2 decimal places
          value: Math.round(predictionData.value[index] * 100) / 100,
        }))
      : [];

    let yAxisDomain = [0, 10];

    if (
      predictionData &&
      predictionData.value &&
      predictionData.value.length > 0
    ) {
      const minValue = Math.min(...predictionData.value);
      const maxValue = Math.max(...predictionData.value);
      yAxisDomain = [minValue - 5, maxValue + 5];
    }

    return (
      <Grid container spacing={3} paddingTop={4} paddingBottom={3}>
        <Grid item xs={4}>
          <Paper
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "16px",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "5px",
              }}
            >
              <Typography variant="h6" align="center">
                Top 5 Popular Area on Site
              </Typography>
              {mostPopularArea && mostPopularArea.length > 0 ? (
                <List>
                  {mostPopularArea.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <Typography color="#282982">{item.name}</Typography>
                        }
                        secondary={
                          <Typography color="#4a90e2">{`Request Count: ${item.count}`}</Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography
                  variant="h6"
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  There is no related data.
                </Typography>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "16px",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" align="center" marginBottom={2}>
              Total Requests for Each Time Period
            </Typography>
            {lineData && lineData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LineChart
                  width={barChartWidth}
                  height={barChartHeight}
                  data={lineData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
                >
                  <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="2%" stopColor="#fff" stopOpacity={0.8} />
                      <stop
                        offset="98%"
                        stopColor="#1a97db"
                        stopOpacity={0.8}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" stroke="#1a97db" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Line
                    type="monotone"
                    name="Request Count"
                    dataKey="total"
                    stroke="#1a97db"
                    fill="url(#gradient)"
                  />
                </LineChart>
              </div>
            ) : (
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bold" }}
              >
                There is no related data.
              </Typography>
            )}
          </Paper>
        </Grid>
        {/* <Grid item xs={4.5}>
        <Paper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" align="center" marginBottom={2}>
            Estimated time spent next month (TCN model)
          </Typography>
          {predictionLineData && predictionLineData.length > 0 ? (
            <LineChart
              width={barChartWidth}
              height={barChartHeight}
              data={predictionLineData}
              margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
            >
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#fff" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#1a97db" stopOpacity={0.8} />
                </linearGradient>
              </defs>
              <XAxis dataKey="time" stroke="#1a97db" />
              <YAxis
                domain={yAxisDomain}
                tickFormatter={(tick) => Math.round(tick)}
              />

              <Tooltip />
              <Legend />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Line
                type="monotone"
                name="Predicted Time"
                dataKey="value"
                stroke="#1a97db"
                fill="url(#gradient)"
              />
            </LineChart>
          ) : (
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              There is no prediction data.
            </Typography>
          )}
        </Paper>
      </Grid> */}
      </Grid>
    );
  } catch (error) {
    console.error(error);
    return <div>Error: {error.message}</div>;
  }
}

export default MainRow2;
