import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

function InstitutionActivityRow1({ data }) {
  const { inactiveDay, UV, perferTechnique } = data;

  const displayData = [
    {
      title: "How long have users been inactive?",
      value: (inactiveDay !== null && inactiveDay <= 10000) ? inactiveDay + " days" : null,
    },
    {
      title: "User Visits (Multiple visits per day count as one)",
      value: UV !== null ? UV : null,
    },
    {
      title: "What is user's preferred technique?",
      value: perferTechnique ? perferTechnique : null,
    },
  ];

  const paperStyle = {
    height: 100,
    width: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#79bee6",
    color: "white",
  };

  const titleStyle = {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const isEmptyOrNullOrUndefined = (data) => {
    return data === null || data === undefined || (Array.isArray(data) && data.length === 0);
  };

  return (
    <Grid container spacing={5} paddingTop={2} paddingBottom={3} justifyContent="center" alignItems="center">
      {displayData.map((item, index) => (
        <Grid item xs={4} key={index}>
          <Paper elevation={4} style={paperStyle}>
            <div style={titleStyle}>
              <Typography variant="subtitle1" align="center" paddingBottom={2}>
                {item.title}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
                {isEmptyOrNullOrUndefined(item.value) ? "There is not related data." : item.value}
              </Typography>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default InstitutionActivityRow1;
