import React, { useState, useEffect } from "react";
import { fetchOverviewData, fetchPredictionData } from "../../api";
import { Grid, Paper, Typography } from "@mui/material";
import MainRow2 from "../charts/MainRow2";
import MainRow3 from "../charts/MainRow3";
import MainRow4 from "../charts/MainRow4";
import MainRow5 from "../charts/MainRow5";
import MainRow6 from "../charts/MainRow6"; 

function MainBox() {
  const [overviewData, setOverviewData] = useState(null);
  const [predictionData, setPredictionData] = useState(null);

  useEffect(() => {
    async function FetchData() {
        try {
          const data = await fetchOverviewData();
          setOverviewData(data);
          // const prediction = await fetchPredictionData();
          // setPredictionData(prediction);
        } catch (error) {
          console.error("Error:", error.message);
        }
    }
    FetchData();
  }, []);

  const todayVisitors = overviewData ? overviewData.todayVisitors : 0;
  const thisWeekVisitors = overviewData ? overviewData.thisWeekVisitors : 0;
  const monthVisitors = overviewData ? overviewData.thisMonthVisitors : 0;
  const thisYearVisitors = overviewData ? overviewData.thisYearVisitors : 0;

  const periods = [
    { label: "Today", visitors: todayVisitors },
    { label: "This Week", visitors: thisWeekVisitors },
    { label: "This Month", visitors: monthVisitors },
    { label: "This Year", visitors: thisYearVisitors },
  ];

  // ROW2 DATA
  const mostPopularArea = overviewData ? overviewData.mostPopularArea : [];
  const timeSpentTotal = overviewData ? overviewData.timeSpentTotal : [];

  // ROW3 DATA
  const usageOfTool = overviewData ? overviewData.usageOfTool : [];
  const mainTabUsage = overviewData ? overviewData.mainTabUsage : [];

  // ROW4 DATA
  const inactiveUserList = overviewData ? overviewData.inactiveUserList : [];
  const activeUserList = overviewData ? overviewData.activeUserList : [];
  const technique = overviewData ? overviewData.technique : [];
  const frequencyUV = overviewData ? overviewData.frequencyUV : 0;

  // ROW5 DATA
  const selectedModes = overviewData ? overviewData.selected_modes : [];
  const selectedLayers = overviewData ? overviewData.selected_layers : [];
  const selectedSamples = overviewData ? overviewData.selected_samples : [];

  // ROW6 DATA
  const originOfUser = overviewData ? overviewData.originOfUser : [];

  return (
    <Grid item xs={9} paddingTop={8}>
      {/* 1st row: Vistor Part */}
      <Grid container spacing={3}>
        {periods.map((period, index) => (
          <Grid item xs={3} key={index}>
            <Paper
              sx={{
                backgroundColor: "#48ace2",
                borderRadius: "10px",
                padding: "16px",
                height: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#31a1df",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                {period.label} Visitors
              </Typography>

              {period.visitors !== null ? (
                <Typography
                  variant="h4"
                  sx={{ color: "white", textAlign: "center" }}
                >
                  {period.visitors}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  sx={{ color: "white", textAlign: "center" }}
                >
                  There is not related data.
                </Typography>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* 2nd row*/}
      <MainRow2
        mostPopularArea={mostPopularArea}
        timeSpentTotal={timeSpentTotal}
        predictionData={predictionData}
      />

      {/* 3rd row*/}
      <MainRow3 usageOfTool={usageOfTool} mainTabUsage={mainTabUsage} />

      {/* 4th row*/}
      <MainRow4
        inactiveUserList={inactiveUserList}
        activeUserList={activeUserList}
        technique={technique}
        frequencyUV={frequencyUV}
      />

      {/* 5th row*/}
      <MainRow5
        selectedModes={selectedModes}
        selectedLayers={selectedLayers}
        selectedSamples={selectedSamples}
      />

      {/* 6th row*/}
      <MainRow6 originOfUser={originOfUser} />
    </Grid>
  );
}

export default MainBox;
