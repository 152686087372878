import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem, Typography } from "@mui/material";
import { fetchUserIdList, fetchUserData } from "../../api";
import Row1 from "../charts/UserActivityRow1";
import Row2 from "../charts/UserActivityRow2";

function UserActivityBox() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userData, setUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userIds = await fetchUserIdList();
        setUsers(userIds);
        if (userIds && userIds.length > 0) {
          setSelectedUser(userIds[0].user_id); // The id of the first user is selected by default
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedUserDetails = async () => {
      if (!selectedUser) return;
      try {
        const data = await fetchUserData(selectedUser);
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          setUserData(data);
          setErrorMessage(null);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchSelectedUserDetails();
  }, [selectedUser]);

  return (
    <Grid
      container
      spacing={3}
      style={{ width: "100%" }}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color="#414291"
          fontWeight="Bold"
          paddingBottom={2}
        >
          User Activity Dashboard
        </Typography>
        <Typography variant="subtitle2" color="#1192d7">
          Select User
        </Typography>
        <Select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          sx={{
            width: 250,
            backgroundColor: "deepblue",
            color: "Black",
            "&:before": {
              borderColor: "deepblue",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderColor: "deepblue",
            },
          }}
        >
          {users.map(({ user_id, name }) => (
            <MenuItem key={user_id} value={user_id}>
              {name} ({user_id})
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {errorMessage ? (
        <Grid item xs={12}>
          <Typography
            variant="h4"
            color="error"
            align="center"
            fontWeight="Bold"
          >
            {errorMessage}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Row1 data={userData} />
          </Grid>
          <Grid item xs={12}>
            <Row2 data={userData} />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default UserActivityBox;
