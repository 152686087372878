import React, { useState } from 'react';
import { MapContainer, TileLayer, Rectangle, Circle } from "react-leaflet";
import { scaleLinear } from "d3-scale";

function InstitutionActivityRow2({ data }) {
  const { heatmapData } = data || {};
  const [timeFilter, setTimeFilter] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  if (!heatmapData || heatmapData.length === 0) {
    return <p>No related heatmap data</p>;
  }

  const now = Date.now();
  const oneDay = 24 * 60 * 60 * 1000;
  const filteredData = heatmapData.filter((d) => {
    if (startDate && endDate) {
      const start = new Date(startDate).getTime();
      const end = new Date(endDate).getTime();
      return d.req_timestamp >= start && d.req_timestamp <= end;
    }

    const daysAgo = (now - d.req_timestamp) / oneDay;
    return timeFilter === 'yesterday' ? daysAgo <= 1 && daysAgo > 0
         : timeFilter === 'last7days' ? daysAgo <= 7
         : timeFilter === '30days' ? daysAgo <= 30
         : timeFilter === '90days' ? daysAgo <= 90
         : timeFilter === '1year' ? daysAgo <= 365
         : true;  // 'all'
  });

  const minTimestamp = Math.min(...filteredData.map((d) => d.req_timestamp));
  const maxTimestamp = Math.max(...filteredData.map((d) => d.req_timestamp));

  const colorScale = scaleLinear()
    .domain([minTimestamp, maxTimestamp])
    .range(["yellow", "green"]);

  const rectangles = filteredData.map((item, index) => {
    const bounds = [
      [item.north, item.west],
      [item.south, item.east],
    ];
    const center = [item.center_y, item.center_x];
    const color = colorScale(item.req_timestamp);

    return (
      <div key={index}>
        <Rectangle bounds={bounds} color="red" fillOpacity={0} weight={1}>
          <Circle
            center={center}
            radius={5000}
            color={color}
            fillOpacity={0.7}
          />
        </Rectangle>
      </div>
    );
  });

  return (
    <div>
      <select value={timeFilter} onChange={(e) => {
          setTimeFilter(e.target.value);
          setStartDate('');
          setEndDate('');
        }}
        style={{ marginRight: '10px' }}>
        <option value="all">All time</option>
        <option value="yesterday">Yesterday</option>
        <option value="last7days">Last 7 days</option>
        <option value="30days">Last 30 days</option>
        <option value="90days">Last 90 days</option>
        <option value="1year">Last year</option>
      </select>
      <input
        type="date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
          setTimeFilter('');
        }}
        style={{ marginRight: '5px' }}
      />
      <input
        type="date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
          setTimeFilter('');
        }}
      />
      <MapContainer
        center={[0, 0]}
        zoom={2}
        style={{ height: "500px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {rectangles}
      </MapContainer>
    </div>
  );
}

export default InstitutionActivityRow2;
