import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";

function UserInterestRow1({ data }) {
  const favoriteSubjects = data.favoriteSubject || [];
  const favoriteAreas = data.favoriteArea || [];
  const favoriteDataPackage = data.favoriteDataPackage || null;
  const frequencyDataPackage = data.frequencyDataPackage || [];

  const favoriteSubjectsData = favoriteSubjects.length
    ? [
        { name: "geochem", value: favoriteSubjects[0].count },
        { name: "sample", value: favoriteSubjects[1].count },
        { name: "fission track", value: favoriteSubjects[2].count },
      ]
    : [];
  favoriteSubjectsData.sort((a, b) => b.value - a.value);

  const areas = favoriteAreas.map((area) => area.area_name);
  const favoriteAreasData = favoriteAreas.map((area) => ({
    name: area.area_name,
    freq: area.count,
  }));

  const barChartData = frequencyDataPackage.map((pkg) => ({
    name: `${pkg.name}`,
    Usage: pkg.count,
  }));

  const paperStyle = {
    height: 350,
    width: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const titleStyle = {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px",
  };

  const CustomTooltip = ({ active, payload, label, coordinate }) => {
    if (active && payload && payload.length) {
      const style = {
        marginLeft: `${coordinate.x - 100}px`,
        marginTop: `${coordinate.y}px`,
        backgroundColor: "#ffffff",
        padding: "10px",
        border: "1px solid #cccccc",
        boxShadow: "0 0 5px #cccccc",
        borderRadius: "5px",
      };

      return (
        <div className="custom-tooltip" style={style}>
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  const noDataMessages = [
    "No subject has been selected by this user.",
    "No area has been selected by this user.",
    "No data package has been selected by this user.",
    "No data package has been selected by this user.",
  ];

  return (
    <Grid
      container
      spacing={5}
      paddingTop={2}
      paddingBottom={3}
      justifyContent="center"
      alignItems="center"
    >
      {[favoriteSubjectsData, areas, [favoriteDataPackage], barChartData].map(
        (data, index) => (
          <Grid item xs={3} key={index}>
            <Paper elevation={3} style={paperStyle}>
              <div style={titleStyle}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  paddingBottom={3}
                >
                  {
                    [
                      "What is this user's favorite subject to use?",
                      "What is this user's favorite area to use?",
                      "What is this user's favorite data package?",
                      "How often is this user used each data package?",
                    ][index]
                  }
                </Typography>
              </div>
              {data.length === 0 ? (
                <Typography ariant="subtitle1" align="center" fontWeight="Bold">
                  {noDataMessages[index]}
                </Typography>
              ) : index === 0 ? (
                <Table
                  size="small"
                  style={{ borderColor: "skyblue", width: "100%" }}
                >
                  <TableBody>
                    {favoriteSubjectsData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ borderColor: "transparent", padding: 5 }}
                          align="center"
                        >
                          <div
                            style={{
                              width: "80%",
                              borderColor: "skyblue",
                              borderStyle: "solid",
                            }}
                          >
                            <Typography
                              variant="subtitle3"
                              style={{ fontWeight: "bold" }}
                            >
                              {item.name}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{ borderColor: "transparent", padding: 2 }}
                          align="center"
                        >
                          <div
                            style={{
                              width: "80%",
                              borderColor: "skyblue",
                              borderStyle: "solid",
                            }}
                          >
                            <Typography
                              variant="subtitle3"
                              style={{ fontWeight: "bold" }}
                            >
                              {item.value}
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : index === 1 ? (
                <BarChart width={250} height={250} data={favoriteAreasData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    stroke="#1a97db"
                    angle={-45}
                    textAnchor="end"
                    fontSize={8}
                    height={80}
                    fontWeight="Bold"
                  />
                  <YAxis fontSize={9} />
                  <Tooltip />
                  <Bar dataKey="freq" fill="#1a97db" />
                </BarChart>
              ) : index === 2 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    ariant="subtitle1"
                    align="center"
                    fontWeight="Bold"
                  >
                    {favoriteDataPackage === null
                      ? noDataMessages[index]
                      : favoriteDataPackage.name}
                  </Typography>
                </div>
              ) : (
                <BarChart width={250} height={250} data={barChartData}>
                  <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="2%" stopColor="#fff" stopOpacity={0.8} />
                      <stop
                        offset="98%"
                        stopColor="#1a97db"
                        stopOpacity={0.8}
                      />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    stroke="#1a97db"
                    angle={-55}
                    textAnchor="end"
                    fontSize={8}
                    height={115}
                    fontWeight="Bold"
                  />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey="Usage"
                    fill="url(#gradient)"
                    stroke="#add8e6"
                    strokeWidth={0.5}
                  />
                </BarChart>
              )}
            </Paper>
          </Grid>
        )
      )}
    </Grid>
  );
}

export default UserInterestRow1;
