import React, { useState, useEffect } from "react";
import { isLoggedIn } from "./api";
import SideBar from "./components/elements/SideBar";
import MainBox from "./components/elements/MainBox";
import UserInterestBox from "./components/elements/UserInterestBox";
import UserActivityBox from "./components/elements/UserActivityBox";
import InstitutionInterestBox from "./components/elements/InstitutionInterestBox";
import InstitutionActivityBox from "./components/elements/InstitutionActivityBox";
import LoginModal from "./components/elements/Login";

function App() {
  const [selectedComponent, setSelectedComponent] = useState("Overview");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await isLoggedIn();
      setIsUserLoggedIn(loggedIn);
    };

    checkLoginStatus();
  }, []);

  const renderComponent = () => {
    if (!isUserLoggedIn) {
      return <LoginModal />; // If the user is not logged in, only the login component will be rendered.
    }
    // After the user logs in, different components are rendered according to the selected component status.
    switch (selectedComponent) {
      case "Overview":
        return <MainBox />;
      case "User Interest":
        return <UserInterestBox />;
      case "User Activity":
        return <UserActivityBox />;
      case "Institution Interest":
        return <InstitutionInterestBox />;
      case "Institution Activity":
        return <InstitutionActivityBox />;
      default:
        return <MainBox />;
    }
  };

  // Conditionally set styles based on isUserLoggedIn state
  const containerStyle = isUserLoggedIn
    ? { flexGrow: 1, padding: "15px" }
    : {
        flexGrow: 1,
        padding: "15px",
        height: "100vh",
        backgroundColor: "#ececf4",
      };

  return (
    <div style={{ display: "flex", backgroundColor: "#ececf4" }}>
      {isUserLoggedIn && (
        <SideBar setSelectedComponent={setSelectedComponent} />
      )}
      <div style={containerStyle}>{renderComponent()}</div>
    </div>
  );
}

export default App;
