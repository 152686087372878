import React from "react";
import { Grid, Typography, Paper, useTheme } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

function UserInterestRow2({ data }) {
  const timeSpentMapData = data.timeSpentMapData || [];
  const usagePerTechnique = data.usagePerTechnique || [];
  let timeSpentTotalVisit = data.timeSpentTotalVisit || [];

  const totalSpentTime = timeSpentMapData.reduce((acc, val) => acc + val, 0);
  const mapPercentage = (
    ((timeSpentMapData[0] || 0) / totalSpentTime) *
    100
  ).toFixed(2);
  const dataPercentage = (
    ((timeSpentMapData[1] || 0) / totalSpentTime) *
    100
  ).toFixed(2);

  const barData = usagePerTechnique.map((item) => ({
    name: item.os_family,
    times: item.count,
  }));

  const order = ["0-6am", "6-12", "13-18", "19-24"];
  timeSpentTotalVisit.sort(
    (a, b) => order.indexOf(a.name) - order.indexOf(b.name)
  );

  const theme = useTheme();

  const paperStyle = {
    padding: "10px",
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Grid
      container
      spacing={3}
      paddingBottom={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            How much time spent on Map vs Data?
          </Typography>
          {(typeof mapPercentage !== "undefined" && mapPercentage !== null) ||
          (typeof dataPercentage !== "undefined" && dataPercentage !== null) ? (
            <>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Map: {mapPercentage || 0}%
              </Typography>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Data: {dataPercentage || 0}%
              </Typography>
              <img
                src={`${process.env.PUBLIC_URL}/map.png`}
                alt="Map"
                style={{
                  borderRadius: "50%",
                  width: "50%",
                  objectFit: "contain",
                }}
              />
            </>
          ) : (
            <Typography variant="subtitle1" align="center" fontWeight="Bold">
              There is not related data.
            </Typography>
          )}
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            Total Usage per technique
          </Typography>
          {barData && barData.length ? (
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                data={barData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={80} />
                <Tooltip />
                <Bar dataKey="times" fill="#48ace2" barSize={30}/>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography variant="subtitle1" align="center" fontWeight="Bold">
              There is not related data.
            </Typography>
          )}
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="subtitle1" marginBottom={2}>
            Time spent (Total Visit)
          </Typography>
          {timeSpentTotalVisit && timeSpentTotalVisit.length ? (
            <LineChart
              width={300}
              height={200}
              margin={{ top: 10, right: 40, bottom: 0, left: 0 }}
              data={timeSpentTotalVisit}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#48ace2" />
            </LineChart>
          ) : (
            <Typography variant="subtitle1" align="center" fontWeight="Bold">
              There is not related data.
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UserInterestRow2;
