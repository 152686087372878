import React, { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import { logout, isLoggedIn } from "../../api";

function UserFooter() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const loggedInStatus = await isLoggedIn();
      setLoggedIn(loggedInStatus);
    };

    checkLoggedIn();
  }, []);

  const handleLogout = async () => {
    const loggedOut = await logout();
    if (loggedOut) {
      // After successfully logging out, you may need to refresh the page or redirect to the login page
      window.location.reload();
    }
  };

  // If the user is logged in, display the logout button; otherwise, display nothing
  return (
    loggedIn && (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="16px"
        marginTop="auto"
      >
        <Button variant="text" color="primary" onClick={handleLogout}>
          Log out
        </Button>
      </Box>
    )
  );
}

export default UserFooter;
