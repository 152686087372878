import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

function calculateBlueShade(maxValue, currentValue) {
  const ratio = currentValue / maxValue;

  const startColor = { r: 42, g: 43, b: 133 };
  const endColor = { r: 17, g: 148, b: 218 };

  const deltaColor = {
    r: endColor.r - startColor.r,
    g: endColor.g - startColor.g,
    b: endColor.b - startColor.b,
  };

  const interpolatedColor = {
    r: Math.round(startColor.r + deltaColor.r * ratio),
    g: Math.round(startColor.g + deltaColor.g * ratio),
    b: Math.round(startColor.b + deltaColor.b * ratio),
  };

  return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
}

function filterDataByTime(data, filter) {
  const now = new Date();
  let fromDate;

  switch (filter) {
    case "week":
      fromDate = new Date(now.setDate(now.getDate() - 7));
      break;
    case "month":
      fromDate = new Date(now.setMonth(now.getMonth() - 1));
      break;
    case "threeMonths":
      fromDate = new Date(now.setMonth(now.getMonth() - 3));
      break;
    case "halfyear":
      fromDate = new Date(now.setMonth(now.getMonth() - 6));
      break;
    case "year":
      fromDate = new Date(now.setFullYear(now.getFullYear() - 1));
      break;
    case "all":
    default:
      return data;
  }

  return data.filter((d) => new Date(d.time) >= fromDate);
}

function MainRow3({ usageOfTool, mainTabUsage }) {
  const [mainTabTimeFilter, setMainTabTimeFilter] = useState("all");
  const [toolTimeFilter, setToolTimeFilter] = useState("all");

  const handleMainTabChange = (event) => {
    setMainTabTimeFilter(event.target.value);
  };

  const handleToolChange = (event) => {
    setToolTimeFilter(event.target.value);
  };

  const filteredMainTabData = mainTabUsage.map((tab) => ({
    title: tab.title,
    data: filterDataByTime(tab.data, mainTabTimeFilter).map((d) => ({
      name: d.time,
      usage: d.count,
    })),
  }));

  const getToolUsageData = (filter) => {
    return usageOfTool.map((tool, index) => {
      let count;
      switch (filter) {
        case "week":
          count = tool.week_count;
          break;
        case "month":
          count = tool.month_count;
          break;
        case "halfyear":
          count = tool.halfyear_count;
          break;
        case "year":
          count = tool.year_count;
          break;
        case "all":
        default:
          count = tool.overall_count;
      }
      return { tool: tool.tool, count };
    });
  };

  const toolUsageData = getToolUsageData(toolTimeFilter);

  const lineColors = ["#1E90FF", "#4169E1", "#4682B4", "#5F9EA0"];

  const CustomLegend = () => null;

  return (
    <Grid container spacing={3} paddingTop={5} marginBottom={3}>
      <Grid item xs={8}>
        <Paper sx={{ padding: "16px", height: "100%" }}>
          <Typography
            variant="h6"
            align="center"
            style={{ marginBottom: "15px" }}
          >
            Usage of Main Tabs
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginBottom: "20px" }}
          >
            <InputLabel id="main-tab-time-filter-label">Time Filter</InputLabel>
            <Select
              labelId="main-tab-time-filter-label"
              value={mainTabTimeFilter}
              onChange={handleMainTabChange}
              label="Time Filter"
            >
              <MenuItem value="week">Past Week</MenuItem>
              <MenuItem value="month">Past Month</MenuItem>
              <MenuItem value="threeMonths">Past Three Months</MenuItem>
              <MenuItem value="halfyear">Past Half Year</MenuItem>
              <MenuItem value="year">Past Year</MenuItem>
              <MenuItem value="all">All Time</MenuItem>
            </Select>
          </FormControl>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            {filteredMainTabData.map(({ title, data }, index) => (
              <Grid
                item
                xs={6}
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" align="center">
                  {title}
                </Typography>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {data.length > 0 ? (
                    <LineChart width={300} height={200} data={data}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend content={CustomLegend} />
                      <Line
                        type="monotone"
                        dataKey="usage"
                        stroke={lineColors[index]}
                        dot={false}
                      />
                    </LineChart>
                  ) : (
                    <Typography>No data about this time filter.</Typography>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={4} sx={{ justifyContent: "center" }}>
        <Paper
          sx={{
            padding: "16px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            align="center"
            style={{ marginBottom: "15px" }}
          >
            Usage of Tools
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginBottom: "20px" }}
          >
            <InputLabel id="tool-time-filter-label">Time Filter</InputLabel>
            <Select
              labelId="tool-time-filter-label"
              value={toolTimeFilter}
              onChange={handleToolChange}
              label="Time Filter"
            >
              <MenuItem value="week">Past Week</MenuItem>
              <MenuItem value="month">Past Month</MenuItem>
              <MenuItem value="halfyear">Past Half Year</MenuItem>
              <MenuItem value="year">Past Year</MenuItem>
              <MenuItem value="all">All Time</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BarChart
              width={500}
              height={500}
              data={toolUsageData}
              margin={{ top: 20, right: 30, left: 20, bottom: 150 }}
            >
              <XAxis
                dataKey="tool"
                tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
                interval={0}
              />
              <YAxis />
              <Tooltip />
              <Legend content={CustomLegend} />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MainRow3;
